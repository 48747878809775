import React, { CSSProperties, useEffect, useState } from 'react';
import { Select } from 'src/antd';
import { useLanguages, useLocale } from '@transifex/react';
import { useCompany } from 'src/app/Company.store';
import { useUserMaybe, useUserStore } from 'src/app/User.store';
import { setMomentLocale } from 'src/utils';
import { tx } from '@transifex/native';
import { handleApiChange } from './shared/FormChangeApiUrl';

const LANGUAGES_MAP: Record<string, string> = {
  da: 'da',
  en: 'en',
  no: 'no_NO',
  fi: 'fi',
  sv: 'sv',
};

export const setTransifexLocale = (language: string = 'en') => {
  const [lang] = language.toLowerCase().split('_');
  const code = LANGUAGES_MAP[lang] || lang;
  tx.setCurrentLocale(code);
};

export function LanguagePicker({ style }: { style?: CSSProperties }) {
  const { data: company } = useCompany();
  const userMaybe = useUserMaybe();
  const language = company?.default_language.toLowerCase();
  const languages = useLanguages();
  const locale = useLocale();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedLocale = userMaybe?.language?.toLowerCase() || language || 'en';
    setTransifexLocale(savedLocale);
    setMomentLocale(savedLocale);
  }, [language, userMaybe?.language]);

  const handleChange = async (v: string) => {
    setLoading(true);
    if (userMaybe) {
      // if there is a user then change the user language there.
      await useUserStore.getState().update({ language: v });
    }
    tx.setCurrentLocale(v);
    setLoading(false);
  };

  return (
    <Select
      onClick={handleApiChange}
      style={style}
      className="language-picker"
      onChange={handleChange}
      options={languages.map(({ code, localized_name }: $TSFixMe) => ({
        value: code,
        label: localized_name,
      }))}
      value={locale}
      loading={loading}
    />
  );
}
