import { useLocale } from '@transifex/react';
import React from 'react';
import Turnstile, { TurnstileProps } from 'react-turnstile';

interface CaptchaProps extends Omit<TurnstileProps, 'sitekey'> {
  onChange?: (token: string) => void;
}

const Captcha: React.FC<CaptchaProps> = ({ onChange, ...props }) => {
  const locale = useLocale();

  return (
    <Turnstile
      {...props}
      sitekey={process.env.REACT_APP_SITEKEY!}
      onVerify={onChange}
      language={locale}
    />
  );
};

export default Captcha;
